
<template>
  <div>
    <!-- 顶部搜索栏 -->
    <el-row>
      <el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container flx-row ali-c">
          <el-input
            placeholder="订单编号"
            class="search-input"
            clearable
            v-model="searchObj.orderNumber"
          ></el-input>
          <el-input
            placeholder="手机号"
            clearable
            class="search-input ml-15"
            v-model="searchObj.phone"
          ></el-input>
          <el-select
            clearable
            placeholder="订单状态"
            class="search-input ml-15"
            v-model="searchObj.status"
          >
            <el-option
              v-for="(item,index) in order_status"
              :key="index"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
          <!-- <el-date-picker
            v-model="searchObj.date"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
            class="ml-15 search-input"
          ></el-date-picker>-->
          <el-select
            clearable
            placeholder="选择门店"
            class="search-input ml-15"
            v-model="searchObj.storeId"
            :disabled="disabled"
          >
            <el-option
              v-for="(item,index) in store_list"
              :key="index"
              :label="item.storeName"
              :value="item.id"
            ></el-option>
          </el-select>
          <!-- <el-select
            clearable
            placeholder="订单类型"
            class="search-input ml-15"
            v-model="searchObj.orderType"
          >
            <el-option
              v-for="(item,index) in order_type"
              :key="index"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select> -->
          <el-button type="primary" icon="el-icon-search" class="ml-15" @click="search">查询</el-button>
          <el-button icon="el-icon-refresh" class="ml-15" @click="reset">重置</el-button>
        </div>
      </el-col>
    </el-row>
    <el-row class="mt-24">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
          <!-- <div class="flx-row ali-c">
            <el-button icon="el-icon-upload2" type="primary" plain @click="exportExcel">导出</el-button>
          </div>-->
          <!-- 表格 -->
          <el-table class="mt-24 member-table" :data="tableData">
            <el-table-column label="订单编号" prop="orderNumber" width="200"></el-table-column>
            <el-table-column label="客户昵称" prop="nickname"></el-table-column>
            <el-table-column label="手机号码" prop="phone"></el-table-column>
            <el-table-column label="所属门店" prop="storeName"></el-table-column>
            <el-table-column label="订单类型">
              <template slot-scope="scope">
                <span>{{getString(0,scope.row.orderType)}}</span>
              </template>
            </el-table-column>
            <el-table-column label="订单金额">
              <template slot-scope="scope">
                <span>￥{{scope.row.actualPay || 0}}</span>
              </template>
            </el-table-column>
            <!-- <el-table-column label="付款方式" prop="pay_way"></el-table-column> -->
            <el-table-column label="订单状态">
              <template slot-scope="scope">
                <span>{{getString(1,scope.row.status)}}</span>
              </template>
            </el-table-column>
            <el-table-column label="下单时间" prop="createTimeToStr" width="250"></el-table-column>
            <el-table-column label="操作" width="200">
              <template slot-scope="scope">
                <div class="flx-row ali-c">
                  <el-button @click="goDetail(scope.row)" type="text">详情</el-button>
                  <el-button
                    type="text"
                    class="ml-10"
                    @click="getDetail(scope.row)"
                    v-if="scope.row.status==1"
                  >生成小票</el-button>
                  <el-button
                    type="text"
                    class="ml-10"
                    v-if="(scope.row.status>0&&scope.row.status<4&&scope.row.actualPay!=0)||scope.row.status==-1"
                    @click="refund(scope.row)"
                  >退款</el-button>
                </div>
                <!-- <span class="txt-tip-info ml-5">
                  <i class="el-icon-money" @click="refund(scope.row.id)"></i>退款
                </span>-->
              </template>
            </el-table-column>
          </el-table>
          <div class="flx-row ali-c js-c mt-24">
            <Pagination
              :total="total"
              :pageNum="pageNum"
              :pageSize="pageSize"
              :pageSizes="pageSizes"
              @changePage="search"
              @syncPageData="syncPageData"
            />
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 小票弹窗 start -->
    <el-dialog :visible.sync="dialogVisible" width="450px">
      <div class="capture" ref="capture">
        <h1 class="tl-c">丽日生活GO</h1>
        <h2 class="tl-c">{{captureObj.storeName}}</h2>
        <h1 class="tl-c">* 已付款 *</h1>
        <div class="hr"></div>
        <div>订单号：{{captureObj.orderNumber}}</div>
        <div>下单时间：{{captureObj.createTime}}</div>
        <div>支付方式：微信</div>
        <div>订单类型：{{getString(0,captureObj.orderType)}}</div>
        <h2>{{captureObj.nickname}}</h2>
        <h2>{{captureObj.phone}}</h2>
        <table class="captureTable">
          <tr>
            <th width="60%">商品名称（规格）</th>
            <th>数量</th>
            <th>单价</th>
            <th>小计</th>
          </tr>
          <tr v-for="(item,index) in captureObj.list" :key="index">
            <td>{{item.goodsName}}</td>
            <td>{{item.goodsNum}}</td>
            <td>{{parseFloat(item.goodMarkPrice).toFixed(2)}}</td>
            <td>{{(item.goodsNum*item.goodMarkPrice).toFixed(2)}}</td>
          </tr>
          <tr>
            <td colspan="4">总数量：{{totalNum}}件 商品总金额：{{parseFloat(captureObj.actualPay).toFixed(2)}}</td>
          </tr>
        </table>
        <div>运费：0.00</div>
        <div>订单总金额：{{parseFloat(captureObj.actualPay).toFixed(2)}}</div>
        <div class="hr"></div>
        <div>满减：0.00</div>
        <div>订单会员等级折扣：0.00</div>
        <div>积分抵扣：0.00</div>
        <div>代金券抵扣：0.00</div>
        <div>商家人工减免：0.00</div>
        <div class="hr"></div>
        <div>余额抵扣：0.00</div>
        <div>购物卡抵扣：0.00</div>
        <h2>实付金额：{{parseFloat(captureObj.actualPay).toFixed(2)}}</h2>
        <vue-qr :text="qrText" :margin="0" colorDark="#000" colorLight="#fff" :size="150"></vue-qr>
        <h2>{{captureObj.qrCode}}</h2>
        <h2>买家留言：{{captureObj.remark || '无'}}</h2>
        <h2>商家备注：无</h2>
      </div>
      <div class="flx-row ali-c js-fe mt-50">
        <el-button class="form-button" type="primary" @click="screenShot">下 载</el-button>
      </div>
    </el-dialog>
    <!-- 小票弹窗 end -->
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import html2canvas from "html2canvas";
import vueQr from "vue-qr";
import { getStoreList } from "@/api/online_department_store/commodity_manage"
import {getOrderList, orderRefund, getOrderDetail} from "@/api/transactions/order/orders_department"
export default {
  components: {
    Pagination,
    vueQr
  },
  data() {
    return {
      qrText: "",
      captureObj: {},
      img: "",
      dialogVisible: false,
      //搜索栏相关数据
      searchObj: {
        orderNumber: "",
        phone: null,
        status: null,
        storeId: null,
        orderType: 7,
        activityId: null
      },
      // 订单状态与门店列表
      order_status: [
        {
          id: 0,
          title: "未支付"
        },
        {
          id: 1,
          title: "已支付"
        },
        {
          id: 2,
          title: "已发货"
        },
        {
          id: 3,
          title: "已完成"
        },
        {
          id: 4,
          title: "退款中"
        },
        {
          id: 5,
          title: "已退款"
        },
        {
          id: 6,
          title: "已失效"
        },
        {
          id: -1,
          title: "退款失败"
        }
      ],
      order_type: [
        {
          id: 2,
          title: "积分活动"
        },
        {
          id: 3,
          title: "砍价活动"
        },
        {
          id: 6,
          title: "多人拼团"
        },
        {
          id: 7,
          title: "限时抢购"
        }
      ],
      store_list: [],
      // 会员表格数据内容
      tableData: [],
      //用于分页
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
      totalNum: 0,
      url: "/order-service/order/smallProgram?export=1",
      disabled: false
    };
  },
  mounted() {
    // // console.log(this.$route.query.activityId)
    this.searchObj.activityId = this.$route.query.activityId
    this.getStores();
    this.initData();
  },
  methods: {
    // 表格导出
    exportExcel() {
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        orderNumber: this.searchObj.orderNumber,
        phone: this.searchObj.phone,
        orderType: this.searchObj.orderType,
        storeId: this.searchObj.storeId,
        status:
          this.searchObj.status || this.searchObj.status == 0
            ? this.searchObj.status
            : 99
      };
      this.downFile(this.url, "订单", data);
    },
    goDetail(row) {
      this.$router.push({
        name: "Orders_department_details",
        query: {
          id: row.id,
          member: row.customer.body && row.customer.body.cdmtype
        }
      });
    },
    getDetail(row) {
      getOrderDetail(row.id).then(res => {
        this.captureObj = res.data.body;
        this.captureObj.storeName = row.storeName;
        this.captureObj.phone = row.phone;
        this.captureObj.nickname = row.nickname;
        this.qrText = `{ "code": ${this.captureObj.qrCode},"type":0,"title":"订单" }`;
        this.totalNum = 0;
        this.captureObj.list.map(item => {
          this.totalNum += item.goodsNum;
        });
        this.dialogVisible = true;
      })
    },
    //点击生成图片
    screenShot() {
      html2canvas(this.$refs.capture, {
        scale: 1,
        dpi: window.devicePixelRatio * 2,
        useCORS: true, // 图片跨域
        width: this.$refs.capture.offsetWidth,
        height: this.$refs.capture.offsetHeight,
        scrollY: 0
        // y: 0
        // x: this.$refs.capture.offsetLeft
      }).then(canvas => {
        // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
        this.img = canvas.toDataURL("image/png");
        this.downloadImg();
        // this.base64Upload(img);
      });
    },
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    downloadImg() {
      var a = document.createElement("a");
      a.download = "小票.jpg";
      a.href = this.img;
      if (navigator.msSaveBlob) {
        // 兼容IE
        var blob = this.dataURLtoBlob(this.img);
        return navigator.msSaveBlob(blob, "小票.jpg");
      }
      a.click();
    },
    //初始化状态
    initData() {
      if (this.$store.getters.storeId) {
        this.searchObj.storeId = this.$store.getters.storeId;
        this.disabled = true;
      }
      let data = {
        pageNum: this.pageNum,
          pageSize: this.pageSize,
          orderNumber: this.searchObj.orderNumber,
          phone: this.searchObj.phone,
          orderType: this.searchObj.orderType,
          storeId: this.searchObj.storeId,
          activityId: this.searchObj.activityId,
          status:
            this.searchObj.status || this.searchObj.status == 0
              ? this.searchObj.status
              : 99
      }
      getOrderList(data).then(res => {
        // // console.log("订单数据", res);
        this.tableData = res.data.body.list;
        this.total = res.data.body.total;
      })
    },
    // 获取门店列表
    getStores() {
      getStoreList().then(res => {
        this.store_list = res.data.body.list;
      })
    },
    //退款
    refund(e) {
      this.$confirm("确定对此订单进行退款?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          let data = {
            id: e.id,
            actualPay: e.actualPay,
            remark: ""
          }
          orderRefund(data).then(res => {
            this.$message({
              type: "success",
              message: res.data.msg
            });
            this.initData();
          });
        })
        
    },
    // 重置搜索条件
    reset() {
        Object.assign(this.searchObj, {
            orderNumber: "",
            phone: null,
            status: null,
            storeId: null,
        })
        this.initData();
    },
    // 获取订单状态与类型
    getString(type, val) {
      let temp = "";
      if (type == 1) {
        this.order_status.forEach(e => {
          if (e.id == val) {
            temp = e.title;
          }
        });
      } else {
        this.order_type.forEach(e => {
          if (e.id == val) {
            temp = e.title;
          }
        });
      }
      return temp;
    },
    search() {
      this.initData();
    },
    syncPageData(data) {
      this.pageNum = data.num;
      this.pageSize = data.size;
    }
  }
};
</script>
<style lang="scss" scoped>
.txt-tip-info {
  // color: #409eff;
  cursor: pointer;
}
.capture {
  padding: 20px;
  color: #000;
}
.capture div {
  margin-bottom: 5px;
}
.captureTable {
  border-collapse: collapse;
  border-top: solid #ccc 1px;
  border-left: solid #ccc 1px;
  width: 100%;
  margin-bottom: 5px;
}
.captureTable td,
.captureTable th {
  border-right: solid #ccc 1px;
  border-bottom: solid #ccc 1px;
  text-align: center;
  height: 40px;
}
.hr {
  height: 1px;
  width: 100%;
  background: #999;
  margin-bottom: 5px;
  display: inline-block;
}
</style>
